.adv-details{
    
    padding: 15px;
    border-radius: 0 0 20px 20px;
}

.first{
    height: 80%;
}
.first img{
   /* height: 100%;
   width: 100%; */
    object-fit: cover;
    border-radius: 20px 20px 0 0;
}
#dark .first{
     background-color: var(--darktert_bg);
 }
 #light .first{
    background-color: var(--lighttert_bg);
}
#dark .first img{
    background-color: var(--darktert_bg);
}
#light .first img{
   background-color: var(--lighttert_bg);
}
#dark .first1{
    background-color: var(--darktert_bg);
}
#light .first1{
   background-color: var(--lighttert_bg);
}
#dark .first1 img{
   background-color: var(--darktert_bg);
}
#light .first1 img{
  background-color: var(--lighttert_bg);
}
/* .main{
    height: 100vh;
    width: 100wh;
} */
.main{
    padding: 30px;
}

/* Default styles for all screen sizes */
.adv-card {
    border-radius: 20px;
    /* height: 280px; */
    
}

.even-card {
    margin-top: 40px;  /* Set a default margin-top for even elements */
    margin-right: 20px;
    margin-left: 20px;
    
}
.odd-card{
    margin-right: 20px;
    margin-left: 20px;
}

@media  only screen and (min-width : 600px) {
    .adv-card{
        width: 85% !important;
    }
}
/* Media query for screens with a maximum width of 425 pixels */
@media only screen and (max-width: 425px) {
    .adv-card {
        width: 70%;
        margin-left: auto;
        margin-right: auto;  /* Override width for adv-cards in smaller screens */
       
    }
    
    .even-card {
        margin-top: 20px;  /* Override margin-top for even elements in smaller screens */
        
    }
    .odd-card{
        margin-top: 20px;
    }
}
.adv-details p {
    position: relative;
    left: 0;
}
.profile{
    right: 0;
}
.advisors{
    height: 400px;
}
.slick-slider{
    height: 100%;
}
.slick-list{
    height: 100%;
}
.slick-slide > div{
    margin: 0 10px;
}
/* Advi.css */
.slick-arrow{
    display: none !important;
}
.blur {
    filter: blur(5px);
  }
.odd-advisor{
    margin-top: 100px;
}





