@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Rymaneco;
  src: url("../../fonts/RymanEco-Regular.otf") format("opentype");
}



/*--------------------------------------------Root Styles Declaration area-----------------------------------------------------*/
:root {
  /* --bk-bg: #000000; */
  --bk-bg: #121212;
  --wt-bg: #ffffff;
  --bg-accent: #484a4d;
  --bk-color: #ffffff;
  --wt-color: #000000;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;



  --first_background:none;
  --body_background:white;
  --body_color:black;
  --link_color:navy;
  --main_background:#f5f7f8;
  --hover_color:#092635;
  --shadow_color:rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  --card_border:black;

  --darksec_bg:#171717;
  --darktert_bg:#1e1e1e;
  --darkbtn_bg:#8e4dff;

  --lightsec_bg:#f5f6f9;
  --lighttert_bg:#eceff8;
  --lightbtn_bg:#8e4dff;
}



body {
  margin: 0;
}
.highlight_text{
  background-color: #e7e7e7;
  color: #000000;
}

@media only screen and (min-width: 1024px) {
  body::-webkit-scrollbar {
    width: 8.5px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: #5f5f5f;
    border-radius: 25px;
  }

  body::-webkit-scrollbar-thumb:hover {
    background-color: #7a7a7a;
  }

  body::-webkit-scrollbar-track {
    background-color: #222222e4;
  }

  body::-webkit-scrollbar-track:hover {
    background-color: #222222e4;
  }
}
/*--------------------------------------------------------------------Ends -----------------------------------------------------*/
#dark {
  background-color: var(--bk-bg);
  color: var(--bk-color);
}

#light {
  background-color: var(--wt-bg);
  color: var(--wt-color);
}

body {
  margin: 0;
}
.App {
  padding: 0rem 6rem;
}
.tablet-message {
  display: none;
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
  background-color: #181818;
  text-align: center;
  padding: 20px;
  z-index: 9999; /* Ensures it's above other content */
  font-size: 20px;
  color: white;
}
/* @media screen and (max-width:1200px) and (min-width:990px){
  .App{
    display: none;
  }
  .foooters{
    display: none;
  }
  .tablet-message{
    display: block;
  }
} */


html {
  /* scroll-snap-type: y mandatory;
  max-height: 100vh;
  overflow-y: scroll;
  scroll-padding-top: 30px; */
  scroll-behavior: smooth;
}
.product_redirect_page{
  scroll-snap-align: start;
}


/*-----------------------------------------------------Navbar Style --------------------------------------------*/

.nav-logo {
  height: 4.5rem;
  width: 5rem;
  z-index: 400;
}

#dark .navbar {
  border: 2px inset rgba(7, 6, 8, 0.2);
  backdrop-filter: blur(10px);
  /* background: radial-gradient(rgba(10, 6, 16,0.2) 0%, rgba(24, 21, 28,0.3) 100%); */
  background: radial-gradient(
    circle at 10% 20%,
    rgba(90, 92, 106, 0.4) 0%,
    rgba(32, 45, 58, 0.5) 81.3%
  );
  /* background: linear-gradient(180.2deg, rgba(30, 33, 48,0.2) 6.8%, rgba(74, 98, 110,0.4) 131%); */
  
}
#light .navbar {
  border: 2px ridge rgba(203, 170, 237, 0.2);
  backdrop-filter: blur(10px);
  background: radial-gradient(
    circle at 18.7% 37.8%,
    rgba(250, 250, 250, 0.3) 0%,
    rgba(225, 234, 238, 0.5) 90%
  );
  
}

.navbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 0;
  z-index: 2000;
}
.nav-left {
  justify-content: flex-start;
}
.nav-right {
  display: flex;
  align-items: center;
}
.nav-m {
  padding: 0px 30px;
}
.switch-lable {
  padding: 2px 10px;
}

/*-------------------------------------------------End of NavbStylear  --------------------------------------------*/
.increase1{
  background: linear-gradient(
    45deg, /* Adjust the angle as needed for the diagonal effect */
    transparent,
    rgba(142, 77, 255, 0.5), /* Semi-transparent #8e4dff color */
    transparent
  );
}
#dark .increase1{
  background: linear-gradient(
    45deg, /* Adjust the angle as needed for the diagonal effect */
    transparent,
    rgba(142, 77, 255, 0.5), /* Semi-transparent #8e4dff color */
    transparent
  );
}
/* ---------------------------------------------------Hambargar style------------------------------------------*/
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#dark a {
  color: #ffffff;
  text-decoration: none;
}
#light a {
  color: #000000;
  text-decoration: none;
}

#dark .footer-a{
  color: #8e4dff;
}
#light .footer-a{
  color: #8e4dff;
}
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
#dark .dropdown {
  background-color: var(--darksec_bg);
}
#light .dropdown {
  background-color: var(--lightsec_bg);
}
.dropdown {
  position: absolute;
  top: 80px;
  width: 300px;
  transform: translateX(-45%);
  background-color: var(--lightsec_bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1.5rem;
  overflow: hidden;
  transition: height var(--speed) ease;
}

.menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  padding: 0.7rem;
  opacity: 60%;
}

.menu-item .icon-button {
  margin-right: 0.5rem;

}

.menu-item:hover {
  opacity: 100%;
}
.toggle-hv {
  margin: 10px;
  width: 160px;
  border: 1px solid #8e4dff;
  padding: 12px 20px;
  border-radius: 20px;
  transition: all 0.5s ease;
  position: absolute;
  right: 4.8%;
  top: 50px;
}
.toggle-p {
  font-size: 10px;
  font-weight: 700;
}

/* ------------------------------------------------End of Hambargar style------------------------------------------*/
.progressMainStyle {
  height: 15px;
  background-color: #8e4dff;
  border-radius: 40px;
  max-width: 87%;
  position: fixed;
  top: 9.3%;


}
.progressMainStyle1{
  width: 5px;
  background-color: #8e4dff;
  border-radius: 40px;
  height: 90px;
  position: fixed;
  top: 40%;
  right: 11%;
}
.blog-active{
  background-color: #8e4dff;
}
/*--------------------------------------------------Divider Css style ----------------------------------------------*/
hr.divider {
  border-top: 1px solid #bbb;
  margin: 1% 5%;
}
.underlinee {
  display: flex;
  min-height: 10vh;
}
.underline1 {
  display: flex;
  min-height: 10vh;
}
/* ------------------------------------------------Header Section styles------------------------------------------*/
#dark .header {
  background-color: var(--bk-bg);
}
.header {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
}

/* .spotlight{
    position:absolute;
    top: 0%;
    left : 13%; 
    width: 200px;
    height: 70vh;
    z-index: 0;
    clip-path: polygon(40% 2%, -19% 100%,128% 100% );
    background:linear-gradient(180deg, white, #ffffff00 75% , #ffffff00 100%);
    transform: rotate(-20deg);
} */

/* ------------------------------------------------Showcase Css------------------------------------------*/
.tinder {
  height: 100vh;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  scroll-snap-align: start;
}

.loaded.tinder {
  opacity: 1;
}

.tinder--cards {
  text-align: center;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  z-index: 1;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8%;
}

.tinder--card {
  display: inline-block;
  width: 35%;
  aspect-ratio: 3/4;
  background: #ffffff;
  padding-bottom: 40px;
  overflow: hidden;
  position: absolute;
  transition: all 635ms ease-out;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
  box-shadow: inset 0 0 0.5px 1px hsla(0, 0%,  
              100%, 0.075),
              /* shadow ring 👇 */
              0 0 0 1px hsla(0, 0%, 0%, 0.05),
              /* multiple soft shadows 👇 */
              0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
              0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),
              0 3.5px 6px hsla(0, 0%, 0%, 0.09);
}

.tinder--card img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  pointer-events: none;
}
.tinder--card > span {
  z-index: 1;
}
.tinder--card > h3 {
  z-index: 1;
}

.tinder--card h3 {
  margin-top: 32px;
  font-size: 16px;
  padding: 0 16px;
  pointer-events: none;
}

.tinder--card p {
  margin-top: 24px;
  font-size: 16px;
  padding: 0 16px;
  pointer-events: none;
}

.tinder--buttons {
  flex: 0 0 100px;
  text-align: center;
  padding-top: 20px;
}

.tinder--buttons button {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 40px;
  width: 60px;
  aspect-ratio: 1/1;
  border: 0;

  display: inline-block;
  margin: 0 8px;
}
#dark .tinder--buttons button {
  background-color: #ffffff;
  color: #000;
}
#light .tinder--buttons button {
  background-color: #ffffff;
  box-shadow: rgb(0, 0, 0, 0.4) 2px 5px 27px;
}

.tinder--buttons button:hover {
  outline: 0;
  background-color: #8e4dff !important;
  transition: 0.4s ease-in-out;
  color: white;
}
.tinder--buttons button > img {
  margin: auto;
}

.tinder--buttons i {
  font-size: 16px;
  vertical-align: middle;
}
.removed {
  display: block;
  transform-style: preserve-3d;
  transform: translate(150%, 10px) rotate(10deg) rotateY(180deg) !important;
}
.removed > span {
  transform: rotateY(180deg);
}

/* #dark .tinder--card{
    color: white;
  } */

/*-------------------------------------------------Product Wheel------------------------------------------------*/

.PandFcontainer {
  position: relative;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: transparent;
  scroll-snap-align: start;
}

.pandfinnerContainer {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35vw;
  /* width: 35vw; */
}

.product_feature_brief {
  align-items: center;
  justify-content: center;
  display: flex;
}

.product_feature_details {
  position: relative;
  /* width: 50%; */
}

.shadow {
  clip-path: polygon(20% 29%, 74% 3%, 75% 101%, 24% 64%);
  transition: 0.3s ease-in;
  background-image: linear-gradient(#4a4a4a00 0%, #8e4dff85 52%);
  height: 34vw;
  width: 60%;
  z-index: 0 !important;
  position: absolute;
  right: 30%;
  /* right: 50%; */
}

.InsertPhotoIcon {
  font-size: 5rem !important;
  color: white !important;
}

.circumference_circle {
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circumference_circle:hover {
  background-image: linear-gradient(307deg, #50163f 0%, #5f0a87 74%);
  box-shadow: 2px 2px 10px 10px rgba(255, 1, 242, 0.3) !important;
  transform: scale(1.2);
  border: 2.5px solid white !important;
  font-weight: bolder;
}

/* .shadow {
      width: 600px;
      height: 400px;
      background-color: rgb(226, 238, 116);
      clip-path: polygon(38% 1%, 58% 3%, 100% 100%, 0% 100%);
      transform: rotate(270deg);
      position: absolute;
      top: 15%;
      z-index: -10;
      left: -50%;
      box-shadow: 10px 20px 20px 30px red;
    } */

.circle {
  width: 0px;
  height: 0px;
  border-radius: 6%;
  /* background-color: red;*/
  transition:
    width 0.5s,
    height 0.5s;
}

.circle.open {
  width: 40vw;
  height: 25vw;
}

.left_circle {
  width: 20vw;
  height: 20vw;
  border-radius: 50%;
  transition:
    width 0.5s,
    height 0.5s;
}

.left_circle.close {
  width: 20vw;
  height: 20vw;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.background {
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: #000000;
  overflow: hidden;
}
.product_feature_details > div {
  z-index: 30;
  position: relative;
  /* background-image: linear-gradient(143deg, #8e4dff 30%, #ffffff 100%); original violet color */
  background-image: linear-gradient(143deg, #202020 30%, #303030 100%);
}

.diameter {
  width: 50%;
  height: 10px;
  opacity: 0.3;
  background-color: black;
  position: absolute;
  z-index: 500;
  left: 0;
  transform: rotate(45deg);
}
.radius {
  width: 50%;
  height: 10px;
  opacity: 1;
  background:
  linear-gradient(to right, #565656, #202020) padding-box,
  linear-gradient(to right, rgba(181, 181, 181, 0.3803921569), #303030)
      border-box;
  position: absolute;
  z-index: 500;
  left: -8%;
  top: 38%;
  transform: rotate(18deg);
}
.radius1 {
  width: 50%;
  height: 10px;
  opacity: 1;
  background:
    linear-gradient(to right, #565656, #202020) padding-box,
    linear-gradient(to right, rgba(181, 181, 181, 0.3803921569), #303030)
      border-box;
  position: absolute;
  z-index: 500;
  left: 8%;
  top: 71%;
  transform: rotate(124deg);
}
.radius2 {
  width: 50%;
  height: 10px;
  opacity: 1;
  background:
    linear-gradient(to right, #565656, #202020) padding-box,
    linear-gradient(to right, rgba(181, 181, 181, 0.3803921569), #303030)
      border-box;
  position: absolute;
  z-index: 500;
  left: 41%;
  top: 71%;
  transform: rotate(54deg);
}
.radius3 {
  width: 50%;
  height: 10px;
  opacity: 1;
  background:
    linear-gradient(to right, #565656, #202020) padding-box,
    linear-gradient(to right, rgba(181, 181, 181, 0.3803921569), #303030)
      border-box;
  position: absolute;
  z-index: 500;
  left: 57%;
  top: 38%;
  transform: rotate(342deg);
}
.radius4 {
  width: 50%;
  height: 10px;
  opacity: 1;
  background:
    linear-gradient(to right, #565656, #202020) padding-box,
    linear-gradient(to right, rgba(181, 181, 181, 0.3803921569), #303030)
      border-box;
  position: absolute;
  z-index: 500;
  left: 25%;
  top: 17%;
  transform: rotate(88deg);
}

.diameter1 {
  width: 100%;
  height: 10px;
  opacity: 0.3;
  background-color: black;
  position: absolute;
  transform: rotate(-45deg);
}

.innerest_circle {
  box-shadow: 5px 2px 10px 10px #272222;
}

.upperdiv_top {
  content: "";
  position: absolute;
  top: -12%;
  left: 24%;
  width: 50%;
  height: 50%;
  background-color: rgb(142, 77, 255);
  rotate: 45deg !important;
  transition: 0.5s ease-in;
  box-shadow: 5px 2px 10px 10px #272222;
}

.upperdiv_left {
  content: "";
  position: absolute;
  top: 26%;
  left: -12%;
  width: 50%;
  height: 50%;
  background-color: rgb(142, 77, 255);
  rotate: 45deg !important;
  transition: 0.5s ease-in;
  box-shadow: 8px -2px 10px 10px #272222;
}

.upperdiv_right {
  content: "";
  position: absolute;
  top: 25%;
  left: 61%;
  width: 50%;
  height: 50%;
  background-color: rgb(142, 77, 255);
  rotate: 45deg !important;
  transition: 0.5s ease-in;
  box-shadow: 5px 2px 10px 10px #272222;
}

.upperdiv_bottom {
  content: "";
  position: absolute;
  top: 62%;
  left: 24%;
  width: 50%;
  height: 50%;
  background-color: rgb(142, 77, 255);
  rotate: 45deg !important;
  transition: 0.5s ease-in;
  box-shadow: 5px 2px 10px 10px #272222;
}
.product_details {
  overflow: hidden;
}

.product_details > h2 {
  font-size: 24px;
  font-weight: 800;
  font-family: "Inter";
  color: white;
  margin-left: 0%;
}
.product_details > ul {
  display: list-item !important;
}

.product_details > ul:nth-of-type(1) {
  margin-top: 5%;
}
.product_details > ul > li {
  display: list-item !important;
  font-size: 19px;
  font-weight: 300;
  letter-spacing: 2.5%;
  font-family: "Inter";
  color: white;
}

.showShadow {
  opacity: 1;
  transition: 1.5s ease;
}
.hideshadow {
  opacity: 0;
  transition: 0.2s ease;
}

/*-------------------------------------------------End of Product Wheel------------------------------------------*/

/* ------------------------------------------------Pricing plan Css------------------------------------------*/

.pricing {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
}
#dark .pricing {
  color: #ffffff;
}
#light .pricing {
  color: #000000;
}

.toggle-button > div {
  align-items: center !important;
  justify-content: center !important;
}

.toggle-button > div > button {
  color: white !important;
  font-weight: 800 !important;

  background-color: black;
  width: 50% !important;
}
.toggle-button > div > button:hover {
  color: white !important;
  font-weight: 800 !important;

  background-color: black;
  width: 50% !important;
}

.apexcharts-tooltip span {
  color: #000 !important;
}

.active-butttonn {
  background-image: linear-gradient(358deg, #8e4dff, #49274d);
  transition: 0.4s ease-out !important;
  border-radius: 5% !important;
  border: 0px solid transparent;
}

.slider1 > div > span {
  color: #8e4dff !important;
  height: 10px !important;
}
.slider1 > div > span > span {
  color: #8e4dff !important;
  font-size: 14px !important;
}

.css-14pt78w-MuiSlider-rail {
  background-color: #a7a7a7 !important;
}
.slider1 > div > span > span:last-child {
  color: #fff !important;
}

.btn-gradient-2 {
  background:
    linear-gradient(to right, #565656, #202020) padding-box,
    linear-gradient(to right, #b5b5b561, #303030) border-box;
  border-radius: 50em;
  border: 7px solid transparent;
}
.btn-gradient-1 {
  background:
    linear-gradient(314deg, #6d6d6d 0%, #3d3d3d 100%) padding-box,
    linear-gradient(to right, #000000 -66%, #ffffff 363%) border-box;
  border: 9px solid transparent;
  overflow: hidden;
}

/* ------------------------------------------------End of Pricing plan css------------------------------------------*/

/*-----------------------------------------------------Footer page---------------------------------------------*/
.g-text {
  margin: 0%;
}
.footer {
  display: flex;
  justify-content: space-between;
  min-height: 25vh;
  padding: 0rem ;
  scroll-snap-align: start;
}
.footerBack{
  background: radial-gradient(
    circle at 10% 20%,
    rgba(90, 92, 106, 0.4) 0%,
    rgba(32, 45, 58, 0.5) 81.3%
  );
  border: 2px inset rgba(7, 6, 8, 0.2);
  backdrop-filter: blur(10px);
}
#light .footerBack{
  background: radial-gradient(
    circle at 18.7% 37.8%,
    rgba(250, 250, 250, 0.3) 0%,
    rgba(225, 234, 238, 0.5) 90%
  );
  border: 2px ridge rgba(203, 170, 237, 0.2);
  backdrop-filter: blur(10px);
}
.lists {
  display: flex;
  align-items: center;

  font-size: 16px;
}
.ft-logo {
  width: 5rem;
  height: auto;
}
.ft-title {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
}
.social-logo {
  width: 25px;
  height: auto;
  margin: 0rem 0.7rem;
  transition: all 0.2s ease;
}
.social-logo:hover {
  transform: scale(1.1);
}
.social {
  display: inline-flex;
  margin: 0rem 2rem;
}
.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  scroll-snap-align: start;
}
.contact-footer {
  display: flex;
}
/*-----------------------------------------------------Blog Css -----------------------------------------------------------*/
.header-blog {
  min-height: fit-content;
  display: flex;
  align-items: center;
}
.blog-header {
  justify-content: center;
}
.blog-image {
  background-color: #8e4dff;
  min-width: 30vw;
  min-height: 35vh;
}

.Header_Container {
  width: 100vw;
  height: 0%;
  position: fixed;
  z-index: 50;
  color: #000;
}
#dark .Header_Container{
  color: #000;
}

.main_header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  position: relative;
  font-weight: 700;
  box-shadow: 2px 2px 5px #752626;
  z-index: 1;
}

.left_icon {
  position: absolute;
  right: 2%;

  height: 30px;
  width: 30px;
  display: flex;

  border-radius: 30px;

  align-items: center;
  justify-content: center;
}

.left_icon:hover {
  cursor: pointer;
  transform: scale(1.2);
  box-shadow: 1px 1px 5px #151414;
}

.Cards_header {
  align-items: center;
  display: flex;
  padding-bottom: 30px;
  position: relative;
  margin-top: 10%;
  z-index: -10;
  right: 5%;    
  width: 5%;
}

.card {
  width: 10%;
  aspect-ratio: 1/1;

  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
  box-shadow: 1px 1px 5px #151414;
}

.card:hover {
  cursor: pointer;
  transform: rotate(0deg) !important;
}

.sub_title {
  position: absolute;
  height: 100%;
  right: 3%;
  top: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.sub_title > div > p {
  color: rgb(255, 255, 255);
  opacity: 0.7;
  font-size: 18px;
  font-weight: 500;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 1px solid;
  padding-right: 5rem;
  background-color: black;
  transition: all 0.2s ease;
}
#dark .sub_title > div > p {
  color: rgb(0, 0, 0);
  background-color: white;
}
.sub_title > div > p:hover {
  transform: translate(-1rem);
  opacity: 1;
}

.fixed_sidebar {
  position: fixed;
  margin-top: 5%;
  right: 0;
  align-items: center;
  justify-content: center;
  text-align: end;
  margin-right: 20px;
}

.fixed_sidebar > p {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
}

.fixed_sidebar > p:hover {
  cursor: pointer;

  text-decoration: underline;
  transform: scale(1.2);
}

.blogContent {
  width: 75vw;
}
.blogcontenth1 {
  font-size: 45px;
  padding-left: 100px;
  padding-top: 15vh;
  padding-bottom: 50px;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 67.5px;
  color: #8e4dff;
}
#dark .blogContent {
  background-color: #000000;
}

.about_company {
  width: 75vw;
  display: flex;
  left: 9%;
  justify-content: center;
  position: relative;
}

.about_company > p {
  position: absolute;
  left: 50px;
  font-weight: 600;
}

.blog_cont_text {
  margin-top: 20px;
  left: 50px;
  font-weight: 300;
  font-size: 18px;
}

.about_content {
  width: 70%;
  padding-right: 20px;
  padding-top: 4%;
  padding-left: 0px;
}

.about_content > h2 {
  font-weight: 800;
  font-size: 35px;
}
.about_content > p {
  font-weight: 300;
  font-size: 17px;
}

.about_company > img {
  width: 35%;
  align-self: self-end;
  right: 0;
}

.timeline {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: 5%;
  margin-bottom: 15%;
  padding: 0rem;
  left: 5rem;
}

.timeline > p {
  font-weight: 600;
}

.year {
  width: 25%;
  position: relative;
  height: 100px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  width: 100%;

  background: linear-gradient(112deg, #a679f4a6, white);
  border-radius: 20px;

  padding: 30px;
  align-items: center;
  text-align: center;
  z-index: 10;
}

.t_tip {
  width: 20%;
  aspect-ratio: 1/1;
  background-color: #d8cfcf;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  position: absolute;
  top: -40px;
  z-index: -10;
}

.benefits {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 5%;
  left: 5rem;
  height: 220px;
}

.benefits > p {
  position: absolute;
  top: 0;
  font-weight: 600;
}

.left_box {
  width: 40%;
  background-color: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border: 6px solid #8e4dff;
}
.righ_box {
  width: 40%;
  background-color: #e8e8e8;
  display: flex;
  align-items: center;
  color: black;
  justify-content: center;
  border: 6px solid #8e4dff;
}

.benefits > svg {
  width: 4em !important;
  height: 11em !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.blog_contactus {
  width: 100%;
  height: 30vh;

  margin-top: 10%;
}

.up_footer {
  height: 60%;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.up_footer > div {
  height: 200%;

  aspect-ratio: 1/1;
  border: 10px solid #8e4dffd4;
  background-color: #cac2c2;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 10;
  bottom: -120%;
}

.up_footer > p {
  position: absolute;
  top: 10px;
  left: 6%;
  font-weight: 600;
  font-size: 20px;
}

.up_footer > p > span {
  text-decoration: underline;
  display: inline !important;
  box-shadow: none !important;
  cursor: pointer;
  background-color: transparent !important;
}

.up_footer > div > p {
  position: absolute;
  top: 20%;
  font-size: 40px;
  font-weight: 700;
}
.down_footer {
  height: 100%;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.brandName {
  width: 30%;
  font-weight: 800;
  font-size: 40px;
  color: rgb(159, 136, 136);
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  justify-content: space-between;
  font-family: Rymaneco;
  font-weight: 100;

  height: 100%;
}
.brandName > img {
  width: 10%;
  aspect-ratio: 1/1;
  margin-right: 2%;
}
.button-wrap {
  font-family:
    helvetica neue,
    helvetica,
    arial,
    sans-serif;
  font-weight: 200;
  font-size: 15px;
  height: 150px;
  width: 900px;
  margin-left: -5%;
  position: absolute;
}
.button-wrap .button {
  width: 10%;
  margin: 10px;
  aspect-ratio: 1;
  text-align: center;
  transition: 0.4s ease-in-out;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;

}
.button-wrap .button:hover {
  cursor: pointer;
  /* color: white; */
  transform: scale(1.1);
}
.button-wrap .button:first-of-type {
  z-index: 1;
  transform: rotate(0deg);
}
.button-wrap .button:first-of-type:hover {
  z-index: 1;
  transform: translate(1rem, 0rem);
  scale: 1.2;
  position: relative;
}
.button-wrap .button:nth-of-type(2) {
  z-index: 2;
  transform: rotate(0deg);
}
.button-wrap .button:nth-of-type(2):hover {
  z-index: 2;
  transform: translate(1rem, 0rem);
  scale: 1.2;
  position: relative;
}
.button-wrap .button:nth-of-type(3) {
  z-index: 3;
  box-shadow: 0 5px 8px -3px #8e4dff,0 4px 6px -4px #8e4dff;
  transform: rotate(0deg);
}
.button-wrap .button:nth-of-type(3):hover {
  z-index: 3;
  transform: translate(1rem, 0rem);
  box-shadow: 0 5px 8px -3px #8e4dff,0 4px 6px -4px #8e4dff;
  scale: 1.2;
  position: relative;
}
.button-wrap .button:nth-of-type(4) {
  z-index: 4;
  transform: rotate(0deg);
}
.button-wrap .button:nth-of-type(4):hover {
  z-index: 4;
  transform: translate(1rem, 0rem);
  scale: 1.2;
  position: relative;
}
.button-wrap .button:nth-of-type(5) {
  z-index: 5;
  transform: rotate(0deg);
}
.button-wrap .button:nth-of-type(5):hover {
  z-index: 5;
  transform: translate(1rem, 0rem);
  scale: 1.2;
  position: relative;
}
.button-wrap .button:nth-of-type(6) {
  z-index: 6;
  transform: rotate(0deg);
}
.button-wrap .button:nth-of-type(6):hover {
  z-index: 6;
  transform: translate(1rem, 0rem);
  scale: 1.2;
  position: relative;
}
.button-wrap .button:last-child {
  z-index: 10;
  transform: rotate(0deg);
  /* background-color: #cdd2d8; */
  background-color: #8e4dff;

}
@media (hover:none){
  .button-wrap .button:hover {
    cursor: pointer;
    /* color: white; */
    transform: scale(1.1);
  }

  .button-wrap .button:first-of-type:hover {
    z-index: 1;
    transform: translate(0rem, 0rem);
    scale: 1.1;
    position: relative;
  }

  .button-wrap .button:nth-of-type(2):hover {
    z-index: 2;
    transform: translate(0rem, 0rem);
    scale: 1.1;
    position: relative;
  }

  .button-wrap .button:nth-of-type(3):hover {
    z-index: 3;
    transform: translate(0rem, 0rem);
    box-shadow: 0 5px 8px -3px #8e4dff,0 4px 6px -4px #8e4dff;
    scale: 1.1;
    position: relative;
  }

  .button-wrap .button:nth-of-type(4):hover {
    z-index: 4;
    transform: translate(0rem, 0rem);
    scale: 1.1;
    position: relative;
  }

  .button-wrap .button:nth-of-type(5):hover {
    z-index: 5;
    transform: translate(0rem, 0rem);
    scale: 1.1;
    position: relative;
  }

  .button-wrap .button:nth-of-type(6):hover {
    z-index: 6;
    transform: translate(0rem, 0rem);
    scale: 1.1;
    position: relative;
  }

}
input {
  text-align: center;
  opacity: 1;
}
.bolgd{
  height: 0.1rem;
}
/*-----------------------------------------------------FAQ page -----------------------------------*/

.sections-title-list-container {
  display: grid;
  margin-left: -20px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}
.sections-title-list-container span:hover {
  text-decoration: underline;
}
.sections-title-list-container span {
  font-size: 20px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999;
}

.Faq_main_container {
  margin-bottom: 5rem;
  scroll-snap-align: center;
}
#light .Faq_subcontainer {
  background-color: var(--lightsec_bg);
  color: black;
}
#dark .Faq_subcontainer {
  background-color: var(--darksec_bg);
}
#dark .faq-cards {
  background-color: var(--darksec_bg);
}
#light .faq-cards {
  background-color: var(--lightsec_bg);
}
#light .faq-cards:hover {
  box-shadow: 0 0 10px var(--lighttert_bg),0 0 10px var(--lighttert_bg);
}
#dark .faq-cards:hover {
  box-shadow: 0 0 10px var(--darktert_bg),0 0 10px var(--darktert_bg);
}
.faq-card-questions {
  color: white;
}
#light .faq-card-questions {
  color: black;
}

.Faq_subcontainer {
  background-color: #181818;
  /* color: #191919; */
  color: white;
}


.popupQandA {
  transition: 0.5s ease-in-out;
  display: block !important;
  box-shadow: 9px 9px 10px 0px rgb(142, 77, 255);
}

@media only screen and (max-width: 430px){
  .sections-title-list-container  {
      grid-template-columns: 1fr;
      margin-left: 20px;
      margin-top: -10px;
  }

}
@media only screen and (max-width: 390px){
  .sections-title-list-container  {
      grid-template-columns: 1fr;
  }


}

/*------------------------------------Side Menu---------------------*/
.floting {
  box-shadow: rgb(14 2 4) 1px 2px 9px;
  position: fixed;
  right: -5.0%;
  padding: 40px;
  padding-right: 80px;
  border: 2px inset rgba(7, 6, 8, 0.2);
  /* background: radial-gradient(rgba(10, 6, 16,0.2) 0%, rgba(24, 21, 28,0.3) 100%); */
  /* background: radial-gradient(
    circle at 10% 20%,
    rgba(90, 92, 106, 0.4) 0%,
    rgba(32, 45, 58, 0.5) 81.3%
  ); */
  background: radial-gradient(
  circle at 90% 20%, /* Start from the right (90%) */
  rgba(90, 92, 106, 0.4) 0%, /* Right color stop */
  rgba(32, 45, 58, 0.5) 100% /* Left color stop */
);

  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  transition: right 0.3s ease-in-out;
}

#light .floting{
  border: 2px ridge rgba(203, 170, 237, 0.2);
  background: radial-gradient(
    circle at 18.7% 37.8%,
    rgba(250, 250, 250, 0.3) 0%,
    rgba(225, 234, 238, 0.5) 90%
  );
}
.floting:hover {
  position: fixed;
  right: -3%;
  padding-right: 70px;
}
.floting-arrow {
  position: fixed;
  color: white;
  right: 3.0%;
}
#light .floting-arrow{
  color: black;
}
.floting > .floting-arrow {
  transition: right 0.3s ease-in-out;
}
.floting:hover > .floting-arrow {
  right:5%;
}

.book-a-demo > div {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.book-a-demo > div > button {
  color: black !important;
  font-weight: 700;
  font-size: 10px;
  width: 100%;
  height: 100%;
  align-self: center;
  text-align: center;
  font-family: Inter;
}
.calendly-overlay::-webkit-scrollbar {
  display: none !important;
}

.calendly-overlay .calendly-popup .calendly-popup-content {
  height: 100%;
  max-height: 700px;
  overflow: hidden;
  height: 361px;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  border-radius: 10px;
  margin-top: 2%;
}

.calendly-overlay .calendly-popup-close {
  position: absolute;
  top: 10% !important;
  right: 12% !important;

  width: 19px;
  height: 19px;
  cursor: pointer;
}

.apexcharts-tooltip {
  color: #000 !important;
}
.apexcharts-text {
  color: #fff !important;
  filter: contrast(0);
  font-weight: 500 !important;
}




/*---------------------------------------------------New Pricing plan **------------------------------------------------------*/
.pricing {
  min-height: 100vh;
}
.pricingBubble {
  right: 6rem;
  height: 90px;
  width: 90px;
  display: none;
}
.pricingBubbleLine {
  height: 50px;
  width: 50px;
  rotate: 66deg;
  z-index: 2;
}
.thirdAxis {
  width: 2px;
  height: 140%;
  background-color: #8e4dff;
  rotate: -63deg;
  left: 49%;
  top: -45%;
  z-index: 0;
  position: absolute;
}

.notfound {
  margin: 22%;
  text-align: center;
}
.card-last{
  display: inline-block;
  width: 35%;
  aspect-ratio: 3/4;
  background: transparent;
  padding-bottom: 40px;
  overflow: hidden;
  position: absolute;
}
.card-last img{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -10;
  pointer-events: none;
}

/**-----------------------------------------Career Pages Css Starts Here-----------------------------------------------------*/

.car_login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.car_line{
  height: 35px;
  width: 100%;
}

.intro{
 scroll-behavior: smooth;
 scroll-snap-type: proximity;
 overflow-y: hidden ;
 scroll-snap-align: start;
}
.process{
  scroll-snap-align: start;
}
.reveal{
  scroll-snap-align: start;
  
}
.job-posts{
  scroll-snap-align: start;
}

.about-us-home{
  scroll-behavior: smooth;
  scroll-snap-type: proximity;
  overflow-y: hidden ;
  scroll-snap-align: start;
 }
 .team-wall{
   scroll-snap-align: start;
 }
.about-middle-page{
  scroll-snap-align: start;
}
.ppp{
  scroll-snap-align: start;
}
.join-us{
  scroll-snap-align: start;
  scroll-snap-type: none;
}
/*--------------------------------------------------------------------------------------------------------------------------------*/


/**-----------------------------------------------------------------------------------------------------------------------------*/
/*-----------------------------------------------------Responsive Design Css ---------------------------------------------*/
@media only screen and (max-width: 1440px) {
  .progressMainStyle1{
    right: 12%;
    max-height: 17.5%;
  }
}

@media only screen and (max-width: 1280px) {
  .progressMainStyle1{
    right: 13.2%;
    max-height: 20%;
}  
.product-div{
    
}
}

@media only screen and (max-width:989px){
  .tinder--cards{
    width: 40%;
  }
}
@media only screen and (max-width: 989px) {
  html {
    scroll-snap-type: none;
    max-height: none;
    overflow-y: scroll;
    scroll-padding-top: 0;
    scroll-behavior: smooth;
  }
  .App {
    padding: 0;
  }
  .navbar {
    width: 100%;
  }
  .switch-lable {
    display: none;
  }

  .contact-footer {
    display: flex;
    flex-direction: column;
  }
  .pricing-div {
    display: flex;
    flex-direction: column;
  }
  #productandfeature {
    display: none;
  }
  .lists {
    display: none;
  }

  .blogcontenth1 {
    font-size: 30px;
    padding-top: 15vh;
    padding-left: 40px;
    padding-bottom: 30px;
    font-weight: 500;
    line-height: 35px;
  }
  .about_company {
    width: 80vw;
    flex-direction: column;
    text-align: center;
  }
  .about_company > img {
    width: 100%;
    padding-top: 10px;
  }
  .about_content {
    width: 100%;
  }
  .sec {
    width: 80vw;
    flex-direction: column-reverse;
    text-align: center;
    padding-bottom: 3rem;
  }
  .timeline {
    display: none;
  }
  .benefits {
    display: none;
  }
  /* .underline {
    display: none;
  } */
  .tinder--card {
    width: 80%;
  }
  .tinder--cards {
    margin-top: 30%;
  }
  .removed {
    display: none;
  }
  .pricingBubble {
    height: 50px;
    width: 50px;
    right: 4.5rem;
    display: none;
  }
  .pricingBubbleLine {
  }
  .thirdAxis {
    height: 85%;
    rotate: -51deg;
    left: 49%;
    top: -16%;

  }
  .floting {
    box-shadow: none;
    right: -9.5%;
    border: none;
    background: none;
    bottom: 0%;
    padding: 0;
    padding-right: 70px;
  }
  .floting:hover {
    position: fixed;
    right: -9.5%;
    padding-right: 70px;
  }
  .floting-arrow {
    display: none;
    color: transparent;
  }
  .floting:hover > .floting-arrow {
    display: none;
  }
  .floting {
    display: none;
  }
  .progressMainStyle {
    top: 8%;
    border-radius: 0;
    max-width: 100%;
    left:6%;
  
  
  }
  .progressMainStyle1 {
  max-width: 100%;
  }
  .Cards_header {
    align-items: center;
    padding-bottom: 30px;
    display:grid;
    margin-top: 100px;
    position: relative;
    z-index: -10;
  }
  .button-wrap .button {
    width: 20%;
    height: 35%;
    margin: 5px;
    border-radius: 0px;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
  }
  .card-last{
    width: 80%;
    box-shadow: 0 10px 15px -3px #8e4dff,0 4px 6px -4px #8e4dff;
  }
  .Header_Container{
    width: 0vw;
    color: #000;
  }
  #dark.Header_Container{
    color: #000;
  }
  .button-wrap {
    font-size: 12px;
    display: flex;
    height: 100px;
    width: 600px;
    top: -25%;
  }
}


@media only screen and (max-width:550px){
  .tinder--cards{
    width: 80%;
  }
}


.blog-contact{
  background: radial-gradient(
    circle at 10% 20%,
    rgba(90, 92, 106, 0.4) 0%,
    rgba(32, 45, 58, 0.5) 81.3%
    
  );
  border: 2px inset rgba(7, 6, 8, 0.2);
  backdrop-filter: blur(10px);
}
#light .blog-contact{
  border: 2px ridge rgba(203, 170, 237, 0.2);
  backdrop-filter: blur(10px);
  background: radial-gradient(
    circle at 18.7% 37.8%,
    rgba(250, 250, 250, 0.3) 0%,
    rgba(225, 234, 238, 0.5) 90%
  );
}



/* Workplace cards */


.workplace-card-container{
  position:relative;
  width:100vmin;
  height:70vmin;
}

.workplace-card{
  position:absolute;
  width: 30%;
  height: 50%;
  border-radius:5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: #e7e7e7;
  cursor:pointer;
}

/* 
.card:hover{
  transform: scale(1) rotate(0deg);
  z-index:1;
}
.card:hover::before{
  opacity: 1;
} */

  /* Workplace cards */

/* -------------------MEDIA QUERIES FOR ALL UNSUPPORTED COMPONENTS STARTS--------------------------- */
@media only screen and (min-width:990px){
  .dlmessage{
    width:200px;
  }
}
@media only screen and (max-width:395px){
  .JobStatus{
    margin-top: 10px;
  }
}
/* @media screen and (min-width:500) and (orientation:landscape) {
  .App{
    display: none;
  }
  .foooters{
    display: none;
  }
  .tablet-message{
    display: block;
  }
} */

@media only screen and (max-width:989px) and (min-width:500px){
  .snakeline{
    display: none;
  }
  .dlmessage{
    width:200px;
  }
}

@media only screen and (max-width:500px){
  .dlmessage{
    width:170px;
  }
}

/* FAQ NOT FOUND POPUP & COOKIE popup */
.faqnotfoundbox{
  width: 30%;
  padding-top: 20px;
  padding-bottom: 100px;
}
@media only screen and (max-width:1300px){
  .faqnotfoundbox{
    width: 50%;
  }
  .CookieConsent{
    width: 35%;
  }
}
@media only screen and (max-width:1000px){
  .faqnotfoundbox{
    width: 60%;
    height: 50%;
    padding-bottom: 80px;
  }
  .CookieConsent{
    width: 45%;
  }
}
@media only screen and (max-width:650px){
  .faqnotfoundbox{
    width: 80%;
    height: 50%;
    padding-top: 0px;
    padding-bottom: 80px;
  }
  .CookieConsent{
    width: 65%;
  }
}
@media only screen and (max-width:420px){
  .faqnotfoundbox{
    width: 80%;
    height: 60%;
    padding-top: 0px;
    padding-bottom: 100px;
  }
  .CookieConsent{
    width: 85%;
  }
}



/* Styles for product page */
#dark .mainback{
  background-color: var(--darksec_bg);
}

#light .mainback{
  background-color: var(--lightsec_bg);
}

#dark .trans:hover{
  /* border-color: rgb(205, 206, 221); */
  box-shadow: rgba(255, 255, 255, 0.12) 0px 0px 32px 0px;
  
}

#light .trans:hover{
  /* border-color: #092635; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  
}

#dark .boxcard{
  background-color: var(--darksec_bg);
}

#light .boxcard{
  background-color: var(--lightsec_bg);
}

#dark .bg-shape1{
  --tw-text-opacity:1;
  --first_background: rgb(142 77 255 / var(--tw-text-opacity));
}
#dark .advisor-content{
  background-color: #18181C;
}
#light .advisor-content{
  background-color: #f5f7f8;
}
#dark .slick-dots li button:before{
  color: white !important;
}
#light .slick-dots li button:before{
  color: black !important;
}


#light .slick-dots li.slick-active button:before {
  opacity: .75;
  color: black;
}
#dark .slick-dots li.slick-active button:before {
  opacity: .75;
  color: white;
}

#dark .alumni-card{
  background-color: var(--darktert_bg);
}
#light .alumni-card{
  background-color: var(--lighttert_bg);
}



/* Advisor card */
#dark .adv-details{
  background-color: #1d1a22;
}
#light .adv-details{
  background-color: #e7e7e7;
}


/* Career Post */
#dark .job-cards{
  background-color: var(--darksec_bg);
}
#light .job-cards{
  background-color: var(--lightsec_bg);
}
#dark .btn-careerpost{
  background-color: var(--darkbtn_bg);
}
#light .btn-careerpost{
  background-color: var(--lightbtn_bg);
  color: white;
}


/* Faq design */
#dark .faq-form{
  background-color: var(--darktert_bg);
}
#light .faq-form{
  background-color: var(--lighttert_bg);
}


/* Contact Form */
#dark .btn-submit{
  background-color: var(--darkbtn_bg);
}
#light .btn-submit{
  background-color: var(--lightbtn_bg);
}