#dark .first-container{
  background-color: var(--darksec_bg);
}
#light .first-container{
  background-color: var(--lightsec_bg);
}
.App{
  overflow: visible !important;
}
#light .highlight-list{
  border-color: black;
}

/* .image {
    background-image: url('./AlumniAssets/Ellipse\ 1@2x.svg');
    background-position: center;
    background-repeat: no-repeat;
    position:relative;
} */
.second-image{
  background-image: url('./AlumniAssets//Ellipse\ 1@2x.svg');
}
.video-container {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
  }
  
  .vdoframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .icons{
    height: "50px";
    width: "50px";
    color: "white";
  }
  .imege1{
    margin-left: -60px;
  }

  .adv-about{
    width: 70%;
  }
  .para2{
    width: 70%;
  }
  .highlights{
    margin-left: 200px;
  }
  .para3{
    width: 70%;
    margin-right: 160px;
  }


  @media screen and(max-width:600px) {

    .name1 h1,.name1 p,.name1 .profile{
      margin-left: 0px !important;
    }
  }


 .main3 p{
  font-family: Inter;
}
.main3 h1,.main3 h2,.main3 h3,.main3 h4{
  font-family: 'poppins';
}

.comment{
  font-family: 'Caveat', cursive;
}
.kalam-light {
  font-family: "Kalam", cursive !important;
  font-weight: 300;
  font-style: normal;
}
.main3 .sec-main p{
  text-align: justify;
  text-justify: inter-word;
}
 h1,p{
  font-family: Inter;
 }