#dark .assessment-form{
    background-color: var(--darktert_bg);
}
#light .assessment-form{
    background-color: var( --lighttert_bg);
}
#dark input{
   color: white;
}
#light input{
    color: black;
}

#dark .assessment-card{
    background-color: var(--darksec_bg);
}
#light .assessment-card{
    background-color: var(--lightsec_bg);
}
h1{
    font-family: "Poppins";
}
p{
    font-family: "Inter";
}
#dark .form-btn{
    background-color: var(--darktert_bg);
}
#light .form-btn{
    background-color: var(--lighttert_bg);
}
#dark .form-btn:focus{
    background-color: var(--darkbtn_bg);
    transition: all 0.6s;
}
#light .form-btn:focus{
    background-color: var(--lightbtn_bg);
}
#dark .form-content-1{
    background-color: var(--darksec_bg);
}
#light .form-content-1{
    background-color: var(--lightsec_bg);
}
.form-btn{
    transition: all 0.5s;
}

  