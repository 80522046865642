#dark .mymodal{
  background-color: var(--darksec_bg);
}
#light .mymodal{
  background-color: var(--lightsec_bg);
}
#dark .popupcard{
  background-color: var(--darktert_bg);
}
#light .popupcard{
  background-color: var(--lighttert_bg);
}
#dark .modal-btn{
  background-color: var(--darkbtn_bg);
}
#light .modal-btn{
  background-color: var(--lightbtn_bg);
}
#dark .btn-nxt{
  background-color: var(--darkbtn_bg);
}
#light .btn-nxt{
  background-color: var(--lightbtn_bg);
}
.for-note{
  background-image: url('./DocumentAsset/backnotes.png');
  background-position: center;
    background-repeat: no-repeat;
}
