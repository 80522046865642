.card {
    position: relative;
    border-radius: 8px;
    height: 400px;
    /* width: 200px; */
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.4s;

    overflow: hidden;
}

/*
.card-blur {
    position: absolute;
    height: 100%;
    width: calc(100% + 1px);
    background-image: url('./Team/Ashwin.jpg');
    opacity: 0;
    transition: opacity 0.15s ease-in;
}

.card:hover .card-blur {
    opacity: 0.6;
}*/

/* .card:hover {
    background-image: url('./Team/Ashwin.jpg');
} */
#akshay.card:hover {
    background-image: url('./Team/AkshayNon.jpg');
}
#aman.card:hover {
    background-image: url("./Team//AmanNon.jpg");
}
#ashwin.card:hover {
    background-image: url('./Team/Ashwin.jpg');
}
#anitha.card:hover {
    background-image: url('./Team/Anitha.jpg');
}

#shrey.card:hover {
    background-image: url('./Team/Shrey_aswal.jpg');
}

#dinesh.card:hover {
    background-image: url('./Team/Dinesh.jpg');
}

/* #somu.card:hover {
    background-image: url('./Team//somu.jpg');
} */
#nandana.card:hover{
    background-image: url('./Team/NandanaNon.jpg');
}
#sudip.card:hover{
    background-image: url('./Team/SudipNon.jpg');
}
#madhavi.card:hover
{
    background-image:url('./Team/MadhaviNon.jpg')
}
#rahul.card:hover
{
    background-image:url('./Team/RahulNon.jpg')
}
#krishnan.card:hover{
    background-image: url('./Team/KrishnanNon.jpg');
}
.clicked.card {
    background-color: #e7e7e7;
}

.foooter {
    z-index: 1;
    position: absolute;
    height: 40px;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 10vh;
    padding: 0rem;
    scroll-snap-align: start;
    background-color: #e7e7e7;
}

svg#curve {
    position: absolute;
    fill: #e7e7e7;
    left: 0;
    bottom: 0;
    width: 400px;
    height: 450px;
}

.connections {
    height: 80px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100px;
    margin: auto;
    margin-left: 150px;
}

.connection {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    background-color: #e7e7e7;
    display: inline-block;
    padding: 5px;
    margin-right: 15px;
    transform: translateY(200px);

    transition: transform 1s cubic-bezier(.46, 1.48, .18, .81);
}

.card:hover .connection {
    transform: translateY(35px);
}

.info {
    font-family: Inconsolata;
    padding-left: 20px;
    transform: translateY(0px);
    color: black;
    transition: transform 1s cubic-bezier(.31, 1.21, .64, 1.02);
}

.card:hover .info {
    transform: translateY(0px);
}

.name {
    font-weight: bolder;
    padding-top: 5px;
}

.job {
    margin-top: 0;
}


.connection.twitter {
    margin-left: 40px;
    height: 35px;
    width: 35px;
    padding: 5px;
    border-radius: 100%;
    overflow: hidden;
    transition-delay: 0.06s;
}

.connection.twitter .icon {
    height: 18px;
    width: 18px;
    margin-top: 4px;
    margin-left: 4px;
    background-position: center;
    background-size: cover;
}

.connection.twitter .icon {
    background-image: url('../styles/Team/TeamLinkedin.png');
}



/* .card {
    background-image: url('../styles/Team/AshwinNon.png');
} */
#akshay.card{
    background-image: url('../styles/Team/Akshay.jpg');
}
#aman.card{
    background-image: url('../styles/Team/aman.jpg');
}
#ashwin.card {
    background-image: url('../styles/Team/AshwinNon.png');
}
#anitha.card {
    background-image: url('./Team//AnithaNon.jpg');
}

#shrey.card {
    background-image: url('./Team/Shrey_aswalNon.jpg');
}

#dinesh.card {
    background-image: url('./Team/Dineshnon.jpg');
}

#somu.card {
    background-image: url('./Team/Coming.jpg');
}
#sudip.card{
    background-image: url('./Team/Sudip.jpg');
}
#nandana.card{
    background-image: url('./Team/Nandana.jpg');
}
#madhavi.card{
    background-image: url('./Team/Madhavi.jpg');
}
#rahul.card{
    background-image: url('./Team/Rahul1.jpg');
}
#krishnan.card{
    background-image: url('./Team/Krishnan.jpg');
}
#textt {
    transform: translateY(-200px);
    transition: all 0.4s;
}

.card:hover #textt {
    transform: translateY(20px);
}

@media only screen and (max-width: 600px) {
    .card {
        top: 0;
        width: 250px;
        margin-bottom: 35px;
    }
    .h-back{
        height: 3200px ;
    }
    .child {
        height: 800px !important;
        margin-left: auto;
        margin-right: auto;
    }

    .front {
        margin-right: 45px;

    }

    .side {
        width: 100%;
    }

    .back-cards {
        margin-left: 15%;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 10px;
    }
    .alu1-card{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        height: 400px !important;
    }
    .alu-card{
        margin-left: auto;
        margin-right: auto;
    }
    

}
/* @media only screen and (min-width: 1280px) {
    .child{
        height: 520px !important;
    }
} */
@media only screen and (min-width: 1024px) {
    .back {
        padding-right: 30px;
    }

    .front {
        width: 100%;
    }
    .back{
        width: 100%;
    }

    .content {
        transform: translatez(70px) scale(0.8);
        line-height: 1.5em;
        top: -10px;
        position: relative;
    }
    
}
@media only screen and (max-width: 1024px) {

    .child{
        height: 1700px;
    }
    
}
@media only screen and (max-width: 912px) {

    .child{
        height: 2000px ;
    }
    
}
@media only screen and (min-width: 1200px) {

    .child{
        height:1200px;
    }
    
}

.parent {
    position: relative;
}

.child {
    border-radius: 20px;
    position: relative;
    -webkit-transition: 2.0s ease-in-out;
    transition: 2.0s ease-in-out;
    /* -webkit-transition: 4.0s ease-in-out;
    transition: 4.0s ease-in-out; */
    transform-style: preserve-3d;
    
}

.side {
    height: 100%;
    position: absolute;
    /* color: #fff; */
    transform-style: preserve-3d;
    backface-visibility: hidden;
    border-radius: 20px;
}

#dark .side {
    background-color: var(--darksec_bg);
}

#light .side {
    background-color: var(--lightsec_bg);
}

.content h1 {
    position: relative;
}

/* .child h1:before {
	content: "";
	position: absolute;
	bottom: -20px;
	height: 3px;
	background-color: #3e3;
	width: 70px;
	left: 50%;
	transform: translateX(-50%);
} */

/* #dark .child {
    background-color: #181818;
}

#light .child {
    background-color: #f5f7f8;
} */

.content h1 {
    position: relative;
}

.front {
    z-index: 2;
    background-size: cover;

}

.back {
    transform: rotateY(180deg);
    z-index: 0;

}

.parent.rotate .child {
    transform: rotateY(180deg);
}


.btn-flip {
    position: absolute;
    right: 0;

}
#dark .btn-flip{
    background-color: var(--darkbtn_bg);
}
#light .btn-flip{
    background-color: var(--lightbtn_bg);
}







/* New Alumni Design*/


.alu-card {
    height: 400px;
    width: 210px;
    /* padding: 20px; */
    color: white;
    background-image: url('./Team/Shrey_aswalNon.jpg');
    background-size: cover;
    display: flex;
    position: relative;
    cursor: pointer;
    border-radius: 20px;
}
/* #ash{
    background-image: url('../styles/Team/AshwinNon.png');
} */

.alu-content {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: rgba(31, 31, 31, 0.85); */
    background-color: #1d1a22;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all 0.3s ease;
}

.alu-content * {
    transform: translateY(-70%);
    transition: all 0.3s ease;
    opacity: 0;
}

/* .alu-content h2{
    font-size: 35px;
    margin: 15px;
    font-weight: 700;
} */

.alu-content p {

    line-height: 0.5cm;
    margin-top: 15px;
}

.alu-card:hover .alu-content {
    opacity: 1;
}

.alu-card:hover .alu-content * {
    opacity: 1;
    transform: translateY(0);
}



.alu1-card{
    background-color: #1d1a22;
    padding: 10px;
    height: 450px;
}
.alu1-card:hover{
    /* box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22); */
    box-shadow: 0 19px 38px rgba(113, 113, 113, 0.3), 0 15px 12px rgba(65, 65, 65, 0.22);
    transition: 0.7s
}
.photo1{
    margin-left: auto;
    margin-right: auto;
}

/* .section2{
    background-image: url('https://w7.pngwing.com/pngs/694/312/png-transparent-green-painted-quotes-text-box-double-quotes-text-box-green.png');
    object-fit: cover;
} */

#dark .section2 .flex, .section2 p{
    border-color: #ffff;
}
#light .section2 .flex, .section2 p{
    border-color: #1d1a22 ;
}
#dark .section2 p{
    border-color: #ffff;
}
#light .section2 p{
    border-color: #1d1a22 ;
}
